import React, { useState } from "react";
import { Radio, Divider, Table } from 'antd';
import { tools } from '../../lib/tables' 

const Pension = ({ data, location}) => {
  const [diet, setDiet] = useState(0);
  const [cleanliness, setCleanliness] = useState();
  const [money, setMoney] = useState();
  const [visit, setVisit] = useState();
  const [communication, setCommunication] = useState();
  const [safety, setSafety] = useState();
  const [social, setSocial] = useState();
  const [result, setResult] = useState();
  const [info, setInfo] = useState();

  const calculate = (diet, cleanliness, money, visit, communication, safety, social) => {
    const mean =  (diet && cleanliness && money && visit && communication && safety && social) ? (diet + cleanliness + money + visit + communication + safety + social)/6 : null
    setResult(mean)
  }

  /*
    1) 適切な食事 diet
    2) 身辺の清潔保持 cleanliness
    3) 金銭管理と買い物 money
    4) 通院と服薬 visit
    5) 他人との意思疎通及び対人関係 communication
    6) 身辺の安全保持及び危機対応 safety
    7) 社会性 social
  */
  return (
    <div>
        <h3>適切な食事</h3>
          <Radio.Group
            onChange={(e) => {
              setDiet(e.target.value)
              calculate(e.target.value, cleanliness, money, visit, communication, safety, social)
            }}
            value={diet}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
        <h3>身辺の清潔保持</h3>
          <Radio.Group
            onChange={(e) => {
              setCleanliness(e.target.value)
              calculate(diet, e.target.value, money, visit, communication, safety, social) 
            }}
            value={cleanliness}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
        <h3>金銭管理と買い物</h3>
          <Radio.Group
            onChange={(e) => {
              setMoney(e.target.value)
              calculate(diet, cleanliness, e.target.value, visit, communication, safety, social)
            }}
            value={money}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
        <h3>通院と服薬</h3>
          <Radio.Group
            onChange={(e) => {
              setVisit(e.target.value)
              calculate(diet, cleanliness, money, e.target.value, communication, safety, social)
            }}
            value={visit}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
        <h3>他人との意思疎通及び対人関係</h3>
          <Radio.Group
            onChange={(e) => {
              setCommunication(e.target.value)
              calculate(diet, cleanliness, money, visit, e.target.value, safety, social)
            }}
            value={communication}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
        <h3>身辺の安全保持及び危機対応</h3>
          <Radio.Group
            onChange={(e) => {
              setSafety(e.target.value)
              calculate(diet, cleanliness, money, visit, communication, e.target.value, social)
            }}
            value={safety}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
        <h3>社会性</h3>
          <Radio.Group
            onChange={(e) => {
              setSocial(e.target.value)
              calculate(diet, cleanliness, money, visit, communication, safety, e.target.value)
            }}
            value={social}
            >
            <Radio value={1}>できる</Radio>
            <Radio value={2}>自発的にできるが時には助言や指導を必要とする</Radio>
            <Radio value={3}>自発的かつ適正に行うことはできないが助言や指導があればできる</Radio>
            <Radio value={4}>助言や指導をしてもできない</Radio>
          </Radio.Group>
      <Divider> 結果 </Divider>
      <p>
          <span> 判定平均： </span> { result ? result : "未入力の項目があります"}
      </p>

      <h3>障害等級の目安</h3>

      { result
          ?  <Table 
              columns={tools.pension.columns} 
              dataSource={tools.pension.dataSource} 
              size="small" 
              pagination={false} 
              style={{marginBottom: "1rem"}}
              bordered 
            />
        : null}
    </div>
  )

}

export default Pension
