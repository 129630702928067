
// import { graphql } from "gatsby"
import React, { useState } from "react";
import { Form, Input, Divider } from 'antd';
import "antd/dist/antd.css";
import { Table } from 'antd';
import { Cite } from '../common/cite'

const columns = [
  {
    title: 'パラメータ',
    dataIndex: 'parameter',
    key: 'parameter',
  },
  {
    title: '値',
    dataIndex: 'value',
    key: 'value',
    align: 'right'
  }
];

const dataSource = [
  {
    key: 'F',
    parameter: '生体利用率 F',
    value: "100[%]"
  }
]


const Lithium = ({ data, location}) => {
  const [clearance, setClearance] = useState();
  const [dose, setDose] = useState();
  const [concentration, setConcentration] = useState();

  const salt = 1.0,
    bioavailability = 1.0

  const calculate = (dose) => {
    const result = (clearance && dose) 
      ? ((salt * bioavailability * (dose / 24.0)) / clearance)
      : null
    setConcentration(result)
  }

  return (
    <div>
      <p>
       下記の薬物動態パラメータにもとづいてリチウムの血中濃度を推定する (<Cite references={data.allReference} index={"ウィンターの臨床薬物動態学の基礎2013"} /> ,p.273) 。
      </p>
       <Table 
          dataSource={dataSource} 
          columns={columns} 
          size="small" 
          pagination={false} 
          style={{marginBottom: "1rem"}}
          bordered 
        />


      <Form
        name="basic"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          label="クレアチニンクリアランス[mL/min]"
          name="crcl"
          rules={[{ required: true, message: 'ClCr[mL/min]' }]}
        >
          <Input 
            placeholder="クレアチニンクリアランス[mL/min]"
            onChange={(e) => {
              setClearance(0.25 * Number(e.target.value))
              calculate(dose)
            }}
          />
        </Form.Item>
        <Form.Item
          label="炭酸リチウム投与量[mg/day]"
          name="dose"
          rules={[{ required: true, message: '炭酸リチウム投与量[mg/day]' }]}
        >
          <Input 
            placeholder="炭酸リチウム投与量[mg/day]"
            onChange={(e) => {
              // mEqに変換
              const adjustedDose = Number(e.target.value) * 8.12/300
              setDose(adjustedDose) 
              calculate(adjustedDose)
            }}
          />
        </Form.Item>
      </Form>
      <Divider> 結果 </Divider>
      <p>
          <span> リチウムクリアランス[mL/hr]= </span> { clearance }
      </p>
      <p>
          <span> リチウム投与量[mEq]= </span> { dose }
      </p>
      <p>
          <span> 推定されたリチウム濃度[mEq/L]= </span> { concentration ? concentration.toFixed(2) : null}
      </p>

    </div>
  )
}

export default Lithium

// export const pageQuery = graphql`
//   query ValproageQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
// }`

