import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Collapse } from 'antd';

import Bmi from '../components/tools/bmi'
import Ccr from '../components/tools/ccr'
import LithiumDose from '../components/tools/lithium-dose'
import Lithium from '../components/tools/lithium'
import Pension from '../components/tools/pension'
import Valproate from '../components/tools/valproate'
import Phenobarbital from '../components/tools/phenobarbital'
import Carbamazepine from '../components/tools/carbamazepine'
import DateCounter from '../components/tools/date-counter'
import Cost from '../components/tools/cost'
import Sad from '../components/tools/sad'
import Pharmacokinetics from '../components/tools/pk'

const { Panel } = Collapse;


const ToolsPage = ({ data, location }) => {
  // console.log("data@ToolsPage", data)
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
      <Layout location={location} title={siteTitle}>
        <div>
          <h1>ツール集</h1>
            <p>
              このページでは、日常診療で用いられる計算をWeb上で簡単にできるように集めてみました。
              十分な検証を経ているわけではないので、実際の臨床ではご自分でも結果の妥当性を確認してからご利用ください。
              特に薬物動態学による推定は、入門テキストに紹介されている原始的な方法で計算しています。
            </p>

          <Collapse style={{marginBottom: "2rem"}}>
            <Panel header="料金シミュレータ" key="cost" id="料金シミュレータ">
              <Cost />
            </Panel>
            <Panel header="日数カウント" key="date-counter">
              <DateCounter />
            </Panel>
            <Panel header="BMI計算" key="bmi">
              <Bmi />
            </Panel>
            <Panel header="SAD Personsスケール" key="sad">
              <Sad />
            </Panel>
            <Panel header="クレアチニンクリアランス推定" key="ccr">
              <Ccr/>
            </Panel>
            <Panel header="経口薬の薬物動態グラフ" key="pharmacokinetics">
              <Pharmacokinetics />
            </Panel>
            <Panel header="リチウム投与量の推定" key="lithium-dose">
              <LithiumDose data={data}/>
            </Panel>
            <Panel header="リチウムの血中濃度推定" key="lithium">
              <Lithium data={data}/>
            </Panel>
            {/*
            <Panel header="バルプロ酸の血中濃度推定" key="valproate">
              <Valproate data={data}/>
            </Panel>
            */}
            <Panel header="フェノバルビタールの血中濃度推定" key="phenobarbital">
              <Phenobarbital data={data}/>
            </Panel>
            <Panel header="カルバマゼピンの血中濃度推定" key="carbamazepine">
              <Carbamazepine data={data}/>
            </Panel>
            <Panel header="障害年金日常生活能力の判定平均" key="pension">
              <Pension />
            </Panel>
          </Collapse>

          {/*
            <Panel header="長谷川式簡易知能評価スケール" key="hdsr">
              <HDSR />
            </Panel>
          */}

        </div>
      </Layout>
  );
};


export default ToolsPage

export const pageQuery = graphql`
  query ToolsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allReference {
      edges {
        node {
          key
          title
          authors
          journal
          date
          publicurl
          date
        }
      }
    }
}`

