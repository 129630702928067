import React, { useState } from "react";
import { Form, Radio, Checkbox, Divider } from 'antd';

/*
 * 自己負担割合 self-pay-rate 1割、3割、10割
 * 診療内容
 * 保険診療 insurance
 *   通常診療
 *     初診、再診
 *   30分程度の延長診療
 *   処方
 *   多数の処方
 *   傷病手当
 * 自由診療 free-care
 *   診断書(当院書式、書式指定)
 *   その他(金額を入力)
 */
const costTable = {
  '再診料': 76,
  '処方せん料': 70,
  '精神療法': 315,
  '精神療法(30分以上)': 410
}
const kanriOptions = [
  {
    label:'傷病手当申請書', value: 100
  },
  {
    label:'診療情報提供書(他院への紹介状)', value: 250
  }
]
const freeOptions = [
  {
    label: '診断書(当院書式)', value: 3520 
  },
  {
    label: '診断書(書式指定)', value: 6000
  },
  {
    label: '福祉手帳診断書', value: 6090
  },
  {
    label: '自立支援医療診断書', value: 3000
  },
  {
    label:'障害年金診断書', value: 8520
  }
]

export const calculateCost = ({rate, visit, prescription, kanri, free}, setCost) => {
  let freeCost = free 
    ? free.reduce((prev, current) => prev + current, 0)
    : 0
  let kanriCost = kanri.reduce((prev, current) => prev + current, 0)
  let value = (rate && visit) 
    ? Math.ceil(((Number(rate) * (Number(visit) + Number(prescription) + Number(kanriCost)) * 10)  + Number(freeCost)).toFixed(0) / 10) * 10
    : null
  setCost && setCost(value)
  return value
}

const Cost = ({ data, location}) => {
  const [rate, setRate] = useState();
  const [visit, setVisit] = useState(0);
  const [prescription, setPrescription] = useState(0);
  const [kanri, setKanri] = useState([]);
  const [free, setFree] = useState(0);
  const [cost, setCost] = useState();

  return (
    <>
      <p>
        窓口で支払う金額を予測する。このシミュレータはあくまでも概算であり、多少の誤差が発生することをご了承ください。なお、オンライン診療の料金計算には対応していません。
      </p>
      <p>
        医療費の自己負担割合は、原則は3割です。ただし、70歳以上の高齢者や自立支援医療の場合には1〜2割に設定されています。
        保険証がない場合には、負担割合は10割(全額負担)になります。
      </p>
      <Form
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item name="rate" label="自己負担割合">
          <Radio.Group
            onChange={(e) => {
              setRate(e.target.value)
              calculateCost({rate: e.target.value, visit, prescription, kanri, free}, setCost)
            }}
            value={rate}
            >
            <Radio value={0.3}>3割</Radio>
            <Radio value={0.2}>2割</Radio>
            <Radio value={0.1}>1割</Radio>
            <Radio value={1.0}>10割</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="sinryo" label="診療の種類">
          <Radio.Group
            onChange={(e) => {
              setVisit(e.target.value)
              calculateCost({rate, visit:e.target.value, prescription, kanri, free}, setCost)
            }}
            value={visit}
            >
            <Radio value={288+410}>初診</Radio>
            <Radio value={costTable['再診料']+costTable['精神療法']}>再診</Radio>
            <Radio value={costTable['再診料']+costTable['精神療法(30分以上)']}>再診(30分以上)</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="prescription" label="処方">
          <Radio.Group
            onChange={(e) => {
              setPrescription(e.target.value)
              calculateCost({rate, visit, prescription:e.target.value, kanri, free}, setCost)
            }}
            value={prescription}
            >
            <Radio value={0}>処方なし</Radio>
            <Radio value={costTable['処方せん料']}>処方あり</Radio>
          </Radio.Group>
        </Form.Item>
        <Checkbox.Group 
          options={kanriOptions} 
          onChange={(checkedValues) => {
            setKanri(checkedValues)
            calculateCost({rate, visit, prescription, kanri:checkedValues, free}, setCost)
          }}
        />
        <Checkbox.Group 
          options={freeOptions} 
          onChange={(checkedValues) => {
            setFree(checkedValues)
            calculateCost({rate, visit, prescription, kanri, free:checkedValues}, setCost)
          }}
        />
      </Form>
      <Divider > 結果 </Divider>
      <p>
          <span> 料金[円]= </span> { cost }
      </p>
    </>

  )
}


export default Cost 
