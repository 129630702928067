import React, { useState } from "react";
import { Form, Input, Divider, Radio } from 'antd';
import "antd/dist/antd.css";
import { Table } from 'antd';
import { Cite } from '../common/cite'

/*
  S(salt factor)  = 0.87
  F(bioavailability) = 100[%]
  Vd(Volume of Distribution) = 0.14[L/kg]
  Cl(Clearance) = 8[mL/kg/hr]
  t_half(half time) = 11[hr]
  
*/
const columns = [
  {
    title: 'パラメータ',
    dataIndex: 'parameter',
    key: 'parameter',
  },
  {
    title: '値',
    dataIndex: 'value',
    key: 'value',
    align: 'right'
  }
];

const dataSource = [
  {
    key: 'salt',
    parameter: '塩係数 S',
    value: "1.0"
  },
  {
    key: 'F',
    parameter: '生体利用率 F',
    value: "80[%]"
  }
]


const Carbamazepine = ({ data, location}) => {
  const [weight, setWeight] = useState();
  const [dose, setDose] = useState();
  const [clearance, setClearance] = useState();
  const [concentration, setConcentration] = useState();

  const salt = 1.0,
    bioavailability = 0.8

  const calculate = () => {
    // Cpss_ave = S*F*(dose/hr)/Cl
    const value = (dose && clearance && weight) 
    ? 10 * salt * bioavailability * ((Number(dose) / 24) / (Number(weight) * Number(clearance)))
    : null
    setConcentration(value)
  }


  return (
    <div>
      <p>
       下記の薬物動態パラメータにもとづいてカルバマゼピンの血中濃度を推定する (<Cite references={data.allReference} index={"ウィンターの臨床薬物動態学の基礎2013"} /> ,p.174) 。
      </p>
       <Table 
          dataSource={dataSource} 
          columns={columns} 
          size="small" 
          pagination={false} 
          style={{marginBottom: "1rem"}}
          bordered 
        />


      <Form
        name="basic"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          label="体重[kg]"
          name="weight"
          rules={[{ required: true, message: '体重[kg]' }]}
        >
          <Input 
            onChange={(e) => {
              setWeight(e.target.value)
              calculate()
            }}
            placeholder="体重[kg]" />
        </Form.Item>
        <Form.Item 
          name="polytherapy" 
          label="多剤併用の有無"
          rules={[{ required: true }]}
        >
          <Radio.Group
            onChange={(e) => {
              setClearance(e.target.value)
            }}
            value={clearance}
            >
            <Radio value={0.064}>単剤治療</Radio>
            <Radio value={0.1}>多剤治療(特にフェニトインやフェノバルビタールとの併用)</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="投与量[mg/day]"
          name="dose"
          rules={[{ required: true, message: '投与量[mg/day]' }]}
        >
          <Input 
            placeholder="投与量[mg/day]"
            onChange={(e) => {
              setDose(e.target.value)
              calculate()
            }}
          />
        </Form.Item>
      </Form>
      <Divider > 結果 </Divider>
      <p>
          <span> カルバマゼピン濃度[mg/L]= </span> { concentration ? concentration.toFixed(1) : null}
      </p>

    </div>
  )
}

export default Carbamazepine


