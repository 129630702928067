import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Form, Input, Divider } from 'antd';
import "antd/dist/antd.css";
import { Table } from 'antd';


/*
 * t_half
 * t_max
 * Vd
 * 消失速度定数 Ke = 0.693/t_half
 * t_max = (ln(Ka) - ln(Ke)) / (Ka - Ke)
 * Ka = Ke + ((ln(Ka) - ln(Ke))/t_max
 * 血中濃度 Cp = F*dose*Ka*(exp(-Ke*t) - exp(-Ka*t)) / (Vd*(Ka - Ke))
 *
 */


const generateData = (state) => {
  const { dose, t_half, t_max, Ka, Vd } = state
  const F = 1.0
  const Ke = 0.693 / t_half

  const Cp = (t) => {
    return F*dose*Ka*(Math.exp(-Ke*t) - Math.exp(-Ka*t)) / (Vd*(Ka - Ke))
  }
  const generateArray = (data, count) => {
    if(count > -1 ) {
      return generateArray([(t_half / 3) * count, ...data], count - 1)
    } else {
      return data
    }
  }

  if(dose && t_half &&  t_max && Ka && Vd) {
    return generateArray([], 20).map(item => {
      // console.log("item", item)
      return {
        name: String(item),
        value: Cp(item)
      }
    })
  } else {
    return []
  }

}


export default class Pharmacokinetics extends PureComponent {
  state = {
    dose: 400,
    t_half: 14.7,
    t_max: 3,
    Ka: 1.84, // 吸収速度定数
    Vd: 0.84*65 // 分布容積 0.84[L/kg]
  };


  render() {

    // console.log("state", this.state)
    return (
      <div>
        <p>
        経口薬の薬物動態を計算して、グラフで描画する。
        事前に入力されているパラメータは、リーマス(炭酸リチウム)を念頭に設定している。
        消失速度定数は 0.693 / 半減期で概算し、分布容積は体重 65kgでの値を推定した。
        </p>
        <p>
        このように推定値はあまり正確ではなく、あくまでも患者さんへの説明に利用する用途で作成した。
        </p>
        <Form
          name="basic"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          initialValues={this.state}
        >
          <Form.Item
            label="投与量[mg]"
            name="dose"
            rules={[{ required: true, message: '投与量[mg]' }]}
          >
            <Input 
              value={this.state.dose}
              onChange={(e) => {
                this.setState({dose: parseFloat(e.target.value)})
              }}
            />
          </Form.Item>
          <Form.Item
            label="半減期[hr]"
            name="t_half"
            rules={[{ required: true, message: '半減期[hr]' }]}
          >
            <Input 
              value={this.state.t_half}
              onChange={(e) => {
                this.setState({t_half: parseFloat(e.target.value)})
              }}
            />
          </Form.Item>
          <Form.Item
            label="最大血中濃度時間[hr]"
            name="t_max"
            rules={[{ required: true, message: '最大血中濃度時間[hr]' }]}
          >
            <Input 
              value={this.state.t_max}
              onChange={(e) => {
                this.setState({t_max: parseFloat(e.target.value)})
              }}
            />
          </Form.Item>
          <Form.Item
            label="吸収速度定数[/hr]"
            name="Ka"
            rules={[{ required: true, message: '吸収速度定数[/hr]' }]}
          >
            <Input 
              value={this.state.Ka}
              onChange={(e) => {
                this.setState({Ka: parseFloat(e.target.value)})
              }}
            />
          </Form.Item>
          <Form.Item
            label="分布容積[L]"
            name="Vd"
            rules={[{ required: true, message: '分布容積[L]' }]}
          >
            <Input 
              value={this.state.Vd}
              onChange={(e) => {
                this.setState({Vd: parseFloat(e.target.value)})
              }}
            />
          </Form.Item>
        </Form>
        <Divider> 結果 </Divider>
        <LineChart
          width={500}
          height={300}
          data={generateData(this.state)}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" label={{ value: '経過時間[hr]'}} dy={-20}/>
          <YAxis label={{ value: '薬物血中濃度[mg]', angle: -90, position: 'insideLeft' }} />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
    </div>);
  }
}




