import React, { useState } from "react";
import { Form, Input, Divider } from 'antd';
import { Cite } from '../common/cite'

const LithiumDose = ({ data, location}) => {
  const calculate = (weight, creatinineClearance, concentration) => {
    // dose = weight * 20 * (creatinineClearance/100) * concentration
    const dose = (weight && creatinineClearance && concentration) 
      ? (Number(weight) * 20 * (Number(creatinineClearance)/100.0) * Number(concentration)  / Number(2))
      : null
    setDose(dose)
  }
  const [weight, setWeight] = useState();
  const [clcr, setClcr] = useState();
  const [concentration, setConcentration] = useState();
  const [dose, setDose] = useState();
  const [info, setInfo] = useState();

  return (
    <div>
      <p>
       <Cite references={data.allReference} index={"chih2007"} /> で提唱された推定式でリチウム投与量を推定する。
      </p>
      <Form
        name="basic"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          label="体重[kg]"
          name="weight"
          rules={[{ required: true, message: '体重[kg]' }]}
        >
          <Input 
            onChange={(e) => {
              setWeight(e.target.value)
              calculate(e.target.value, clcr, concentration)
            }}
            placeholder="体重[kg]" />
        </Form.Item>
        <Form.Item
          label="クレアチニンクリアランス[mL/min]"
          name="crcl"
          rules={[{ required: true, message: 'ClCr[mL/min]' }]}
        >
          <Input 
            placeholder="クレアチニンクリアランス[mL/min]"
            onChange={(e) => {
              setClcr(e.target.value)
              calculate(weight, e.target.value, concentration)
            }}
          />
        </Form.Item>
        <Form.Item
          label="期待するリチウム濃度[mEq/L]"
          name="concentration"
          rules={[{ required: true, message: 'リチウム濃度[mEq/L]' }]}
        >
          <Input 
            placeholder="リチウム濃度[mEq/L]"
            onChange={(e) => {
              setConcentration(e.target.value)
              calculate(weight, clcr, e.target.value)
            }}
          />
        </Form.Item>
      </Form>
      <Divider> 結果 </Divider>
      <p>
          <span> リチウム投与量[mg/day]= </span> { dose }
      </p>

      <h3>{info}</h3>
    </div>
  )
}


export default LithiumDose

