import React, { useState } from "react";
import { Form, Input, Radio, Divider } from 'antd';

/*
S 性別 男性 1点、女性 0点
A age：中高年
depression：D うつの存在 
自殺企図歴 attempt previous
アルコール乱用 abuse ethanol 
合理的な思考法ができなくなっている状態 loss thinking rational ：幻聴や妄想の存在
社会的な援助の有無 lacking support social 
具体的な自殺の計画 plan organized：O
配偶者がいない spouse no：N
慢性疾患の有無


*/
export const calculate = (points, setResult) => {
  const [sex, age, depressed, suicidal, alcoholic, rational, social, planned, married, sicked] = points
  const sum = (sex && age && depressed && suicidal && alcoholic && rational && social && planned && married && sicked) 
  ? (points.map((item) => Number(item)).reduce((prev, current) => prev + current, 0))
  : null
  setResult && setResult(sum)
  return sum
}

const Sad = ({ data, location}) => {
  const [sex, setSex] = useState();
  const [age, setAge] = useState();
  const [depressed, setDepressed] = useState();
  const [suicidal, setSuicidal] = useState();
  const [alcoholic, setAlcoholic] = useState();
  const [rational, setRational] = useState();
  const [social, setSocial] = useState();
  const [planned, setPlanned] = useState();
  const [married, setMarried] = useState();
  const [sicked, setSicked] = useState();
  const [result, setResult] = useState();

  return (
        <div>
          <p>
           <a href="https://en.wikipedia.org/wiki/SAD_PERSONS_scale"> SAD Personsスケール </a> でうつ状態を評価する
          </p>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item name="radio-group" label="性別">
              <Radio.Group
                onChange={(e) => {
                  setSex(e.target.value)
                  calculate([e.target.value ,age, depressed, suicidal, alcoholic, rational, social, planned, married, sicked], setResult)
                }}
                value={sex}
                >
                <Radio value={"1"}>生物学的男性</Radio>
                <Radio value={"0"}>生物学的女性</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="年齢[歳]"
              name="age"
              rules={[{ required: true, message: '年齢[歳]' }]}
            >
              <Input 
                onChange={(e) => {
                  const input = Number(e.target.value)
                  const point = (input < 19) || (input > 45) ? "1" : "0"
                  setAge(point)
                  calculate([sex, point, depressed, suicidal, alcoholic, rational, social, planned, married, sicked], setResult)
                }}
                placeholder="年齢[歳]" />

            </Form.Item>
            <Form.Item
              label="うつ状態の有無"
              name="depressed"
            >
              <Radio.Group
                onChange={(e) => {
                  setDepressed(e.target.value)
                  calculate([sex, age, e.target.value, suicidal, alcoholic, rational, social, planned, married, sicked], setResult)
                }}
                value={depressed}
                >
                <Radio value={"1"}>うつ状態である</Radio>
                <Radio value={"0"}>うつ状態ではない</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="自殺企図の有無"
              name="suicidal"
            >
              <Radio.Group
                onChange={(e) => {
                  setSuicidal(e.target.value)
                  calculate([sex, age, depressed, e.target.value, alcoholic, rational, social, planned, married, sicked], setResult)
                }}
                value={suicidal}
                >
                <Radio value={"1"}>自殺企図の既往あり</Radio>
                <Radio value={"0"}>自殺企図の既往なし</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="アルコール乱用の有無"
              name="alcoholic"
            >
              <Radio.Group
                onChange={(e) => {
                  setAlcoholic(e.target.value)
                  calculate([sex, age, depressed, suicidal, e.target.value, rational, social, planned, married, sicked], setResult)
                }}
                value={alcoholic}
                >
                <Radio value={"1"}>アルコールの乱用がある</Radio>
                <Radio value={"0"}>アルコールの乱用がない</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="合理的思考の有無"
              name="rational"
            >
              <Radio.Group
                onChange={(e) => {
                  setRational(e.target.value)
                  calculate([sex, age, depressed, suicidal, alcoholic, e.target.value, social, planned, married, sicked], setResult)
                }}
                value={rational}
                >
                <Radio value={"1"}>合理的な思考ができない</Radio>
                <Radio value={"0"}>合理的な思考ができる</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="社会的援助の有無"
              name="social"
            >
              <Radio.Group
                onChange={(e) => {
                  setSocial(e.target.value)
                  calculate([sex, age, depressed, suicidal, alcoholic, rational, e.target.value, planned, married, sicked], setResult)
                }}
                value={social}
                >
                <Radio value={"1"}>社会的な援助がない</Radio>
                <Radio value={"0"}>社会的な援助がある</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="希死念慮の具体性の有無"
              name="planned"
            >
              <Radio.Group
                onChange={(e) => {
                  setPlanned(e.target.value)
                  calculate([sex, age, depressed, suicidal, alcoholic, rational, social, e.target.value, married, sicked], setResult)
                }}
                value={planned}
                >
                <Radio value={"1"}>希死念慮に具体性がある</Radio>
                <Radio value={"0"}>希死念慮に具体性がない</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="配偶者の有無"
              name="married"
            >
              <Radio.Group
                onChange={(e) => {
                  setMarried(e.target.value)
                  calculate([sex, age, depressed, suicidal, alcoholic, rational, social, planned, e.target.value, sicked], setResult)
                }}
                value={married}
                >
                <Radio value={"1"}>配偶者がいない</Radio>
                <Radio value={"0"}>配偶者がいる</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="慢性疾患の有無"
              name="sicked"
            >
              <Radio.Group
                onChange={(e) => {
                  setSicked(e.target.value)
                  calculate([sex, age, depressed, suicidal, alcoholic, rational, social, planned, married, e.target.value], setResult)
                }}
                value={sicked}
                >
                <Radio value={"1"}>慢性疾患がある</Radio>
                <Radio value={"0"}>慢性疾患がない</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Divider> 結果 </Divider>
          <p>
              <span> 点数= </span> { result }
          </p>

        </div>
  )
}


export default Sad 

