import React, { useState } from "react";
import { Form, Input, Radio, Divider } from 'antd';

export const calculateCcr = (sex, age, weight, serumCr, setCcr) => {
  let value;
  switch (sex) {
    case "male":
      value =  (serumCr && age && weight && sex) ? (((140 - (Number(age))) * Number(weight)) / (72 * Number(serumCr)) ).toFixed(1) : null
      break;
    case "female":
      value =  (serumCr && age && weight && sex) ? (((140 - (Number(age))) * Number(weight)) / (72 * Number(serumCr)) * 0.85 ).toFixed(1) : null
      break;
    default:
      value = null
  }
  setCcr && setCcr(value)
  return value
}


const Ccr = ({ data, location}) => {
  const [sex, setSex] = useState();
  const [age, setAge] = useState();
  const [weight, setWeight] = useState();
  const [scr, setScr] = useState();
  const [ccr, setCcr] = useState();
  const [info, setInfo] = useState();

  return (
        <div>
          <p>
           <a href="https://pubmed.ncbi.nlm.nih.gov/1244564/"> Cockcroft-Gault式 </a>でクレアチニンクリアランスを推定する。
          </p>
          <Form
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item name="radio-group" label="性別">
              <Radio.Group
                onChange={(e) => {
                  setSex(e.target.value)
                  calculateCcr(e.target.value ,age, weight, scr, setCcr)
                }}
                value={sex}
                >
                <Radio value={"male"}>生物学的男性</Radio>
                <Radio value={"female"}>生物学的女性</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="年齢[歳]"
              name="age"
              rules={[{ required: true, message: '年齢[歳]' }]}
            >
              <Input 
                onChange={(e) => {
                  setAge(e.target.value)
                  calculateCcr(sex,e.target.value, weight, scr, setCcr)
                }}
                placeholder="年齢[歳]" 
              />
            </Form.Item>

            <Form.Item
              label="体重[kg]"
              name="weight"
              rules={[{ required: true, message: '体重[kg]' }]}
            >
              <Input 
                onChange={(e) => {
                  setWeight(e.target.value)
                  calculateCcr(sex,age, e.target.value,scr,setCcr)
                }}
                placeholder="体重[kg]" />
            </Form.Item>
            <Form.Item
              label="血清クレアチニン値[mg/dL]"
              name="scr"
              rules={[{ required: true, message: '血清クレアチニン[mg/dL]' }]}
            >
              <Input 
                placeholder="血清クレアチニン値[mg/dL]"
                onChange={(e) => {
                  setScr(e.target.value)
                  calculateCcr(sex, age, weight, e.target.value, setCcr)
                }}
              />
            </Form.Item>
          </Form>
          <Divider > 結果 </Divider>
          <p>
              <span> CCr[mL/min]= </span> { ccr }
          </p>
          <p>
              <span> CCr[mL/hr]= </span> { ccr * 60}
          </p>

          <h3>{info}</h3>
        </div>
  );
}


export default Ccr

