import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from 'react-dates';
import 'moment/locale/ja'; // 日本語ローカライズ
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Divider } from 'antd';


const DateCounter = ({ data, location}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [result, setResult] = useState(null);


  const calculate = (startDate, endDate) => {
    const days = (startDate && endDate ) 
      ? (1 + endDate.diff(startDate, "days"))
      : null
    setResult(days)
  }
  return (
    <>
    <DateRangePicker
      startDate={startDate}
      startDateId="startDateId"
      endDate={endDate}
      endDateId="endDateId"
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      withPortal={true}
      orientation="vertical"
      onDatesChange={(selectedDates) => {
        setStartDate(selectedDates.startDate);
        setEndDate(selectedDates.endDate);
        console.log("startDate",selectedDates.startDate)
        console.log("endDate",selectedDates.endDate)
        calculate(selectedDates.startDate, selectedDates.endDate)
      }}
      isOutsideRange={() => false }
    />
    <Divider > 結果 </Divider>
    <p>
        <span> 日数[days]= </span> { result }
    </p>
    </>

  );
};



export default DateCounter 


