import React, { useState } from "react";
import { Form, Input, Divider } from 'antd';
import "antd/dist/antd.css";
import { Table } from 'antd';
import { Cite } from '../common/cite'

/*
  S(salt factor)  = 0.87
  F(bioavailability) = 100[%]
  Vd(Volume of Distribution) = 0.14[L/kg]
  Cl(Clearance) = 8[mL/kg/hr]
  t_half(half time) = 11[hr]
  
*/
const columns = [
  {
    title: 'パラメータ',
    dataIndex: 'parameter',
    key: 'parameter',
  },
  {
    title: '値',
    dataIndex: 'value',
    key: 'value',
    align: 'right'
  }
];

const dataSource = [
  {
    key: 'salt',
    parameter: '塩係数 S',
    value: "0.9"
  },
  {
    key: 'F',
    parameter: '生体利用率 F',
    value: "100[%]"
  },
  {
    key: 'Cl',
    parameter: 'クリアランス Cl',
    value: "0.1[L/kg/day]"
  },
]


const Phenobarbital = ({ data, location}) => {
  const salt = 1.0,
    bioavailability = 0.9,
    clearance = 0.1

  const calculate = (dose) => {
    // Cpss_ave = S*F*(dose/hr)/Cl
    const concentration = (dose && clearance) 
      ? salt * bioavailability *(1000 * (Number(dose) / 24) / Number(clearance))
      : null
    setConcentration(concentration)
  }

  const [concentration, setConcentration] = useState();

  return (
    <div>
      <p>
       下記の薬物動態パラメータにもとづいてフェノバルビタールの血中濃度を推定する (<Cite references={data.allReference} index={"ウィンターの臨床薬物動態学の基礎2013"} /> ,p.303) 。
      </p>
       <Table 
          dataSource={dataSource} 
          columns={columns} 
          size="small" 
          pagination={false} 
          style={{marginBottom: "1rem"}}
          bordered 
        />


      <Form
        name="basic"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          label="投与量[mg/day]"
          name="dose"
          rules={[{ required: true, message: '投与量[mg/day]' }]}
        >
          <Input 
            placeholder="投与量[mg/day]"
            onChange={(e) => {
              calculate(e.target.value)
            }}
          />
        </Form.Item>
      </Form>
      <Divider> 結果 </Divider>
      <p>
          <span> フェノバルビタール濃度[mg/L]= </span> { concentration ? concentration.toFixed(1) : null}
      </p>

    </div>
  )
}

export default Phenobarbital


