import React, { useState } from "react";
import { Form, Input, Divider, Table } from 'antd';
import { tools } from '../../lib/tables' 

export const calculateBMI = (height, weight, setBmi) => {
  let value =  (height && weight) ? ((Number(weight) / (Number(height) * Number(height))) * 10000).toFixed(1) : null
  value && setBmi && setBmi(value)
  return value
}
export const calculateIdealWeight = (height, setIdealWeight) => {
  let value =  height ? (((Number(height) * Number(height))) * 22 / 10000).toFixed(1) : null
  value && setIdealWeight && setIdealWeight(value)
  return value
}


const Bmi = ({ data, location}) => {
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [idealWeight, setIdealWeight] = useState();
  const [bmi, setBmi] = useState();
  const [info, setInfo] = useState();

  return (
    <>
      <p>
        <a href="https://www.e-healthnet.mhlw.go.jp/information/dictionary/metabolic/ym-002.html"> Body Mass Index(BMI) </a>を計算する。
      </p>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          label="身長[cm]"
          name="height"
          rules={[{ required: true, message: '身長[cm]' }]}
        >
          <Input 
            onChange={(e) => {
                    setHeight(e.target.value)
                    calculateBMI(e.target.value, weight, setBmi)
                    calculateIdealWeight(e.target.value, setIdealWeight)
            }}
            placeholder="身長[cm]" />
        </Form.Item>

        <Form.Item
          label="体重[kg]"
          name="weight"
          rules={[{ required: true, message: '体重[cm]' }]}
        >
          <Input 
            onChange={(e) => {
              setWeight(e.target.value)
              calculateBMI(height, e.target.value, setBmi)
            }}
            placeholder="体重[kg]" />
        </Form.Item>
      </Form>
      <Divider > 結果 </Divider>
      <p>
          <span> BMI= </span> { bmi }
      </p>
      <p>
          <span> 標準体重= </span> { idealWeight }
      </p>

      { bmi
          ?  <Table 
              columns={tools.bmi.columns} 
              dataSource={tools.bmi.dataSource} 
              size="small" 
              pagination={false} 
              style={{marginBottom: "1rem"}}
              bordered 
            />
        : null}
    </>
  );

}


export default Bmi 


